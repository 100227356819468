<template>
  <svg :width="size" :height="size" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.19526 13.8047C2.45561 14.0651 2.87772 14.0651 3.13807 13.8047L8 8.94281L12.8619 13.8047C13.1223 14.0651 13.5444 14.0651 13.8047 13.8047C14.0651 13.5444 14.0651 13.1223 13.8047 12.8619L8.94281 8L13.8047 3.13807C14.0651 2.87772 14.0651 2.45561 13.8047 2.19526C13.5444 1.93491 13.1223 1.93491 12.8619 2.19526L8 7.05719L3.13807 2.19526C2.87772 1.93491 2.45561 1.93491 2.19526 2.19526C1.93491 2.45561 1.93491 2.87772 2.19526 3.13807L7.05719 8L2.19526 12.8619C1.93491 13.1223 1.93491 13.5444 2.19526 13.8047Z"
      :fill="fill"
    />
  </svg>
</template>

<script>
export default {
  props: {
    fill: { type: String, required: false, default: '#1D1E20' },
    size: { type: Number, required: false, default: 16 },
  },
};
</script>
