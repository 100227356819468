<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.76827 22C2.27376 22 1.30723 20.4205 1.9874 19.0898L10.2191 2.98431C10.9617 1.53127 13.0382 1.53127 13.7808 2.98431L22.0125 19.0898C22.6927 20.4205 21.7261 22 20.2316 22H3.76827ZM12 7.5C11.1882 7.5 10.5457 8.1867 10.5997 8.99668L10.9334 14.0022C10.9709 14.5637 11.4372 15 12 15C12.5627 15 13.029 14.5637 13.0665 14.0022L13.4002 8.99668C13.4542 8.1867 12.8117 7.5 12 7.5ZM13.25 17.25C13.25 16.5596 12.6903 16 12 16C11.3096 16 10.75 16.5596 10.75 17.25C10.75 17.9404 11.3096 18.5 12 18.5C12.6903 18.5 13.25 17.9404 13.25 17.25Z"
      :fill="fill"
    />
  </svg>
</template>
<script>
export default {
  props: {
    fill: { type: String, required: false, default: 'currentColor' },
    size: { type: Number, required: false, default: 24 },
  },
};
</script>
